<template lang="pug">
    .auth-page
        //header.serials-page__header
        //    HeaderBase
        //    HeaderMenu
        main.auth-page__container
            AuthWrapper( :hide-description="true")
                ul.register__through.uk-flex.uk-flex-between.uk-flex-bottom(data-uk-tab v-if="!isDomainKg")
                    li(:class="{'uk-active': isPhoneRegister}")
                        a(@click="isPhoneRegister = true") номер телефона
                    li(:class="{'uk-disabled': lockEmailRegister, 'uk-active': !isPhoneRegister}")
                        a(@click="isPhoneRegister = false") E-mail
                form(@submit.prevent="login")
                    InputField.register__field(
                        v-if="isPhoneRegister"
                        type="tel"
                        icon="phone"
                        placeholder="Номер телефона"
                        v-model="form.phone"
                        :disabled="buttonDisabled"
                        autocomplete="tel"
                    )
                    InputField.register__field(
                        v-else
                        type="email"
                        icon="mail"
                        placeholder="E-mail"
                        v-model="form.email"
                        :disabled="buttonDisabled"
                        autocomplete="on"
                    )
                    InputField.register__field(
                        type="password"
                        icon="lock"
                        placeholder="Пароль"
                        v-model="form.password"
                        autocomplete="current-password"
                    )
                    Errors.uk-margin-bottom(
                        :errors="errors"
                        v-if="Object.keys(errors).length"
                    )
                    button.register__btn(
                        type='submit'
                        :disabled="buttonDisabled"
                    ) Войти
                //button.register__reset-btn(@click.self="$router.push({name: 'Reset'})") Восстановление пароля
                template(#footer)
                    Agreement(type="login")


</template>

<script>
import HeaderBase from "@/components/Header";
import HeaderMenu from "@/components/HeaderMenu";
import AuthWrapper from "@/views/auth/components/AuthWrapper";
import InputField from "@/components/InputField";
import validatePassword from "@/mixins/validatePassword";
import RegisterMixins from "@/mixins/register";
import Errors from "@/components/Errors";
import utils from "@/mixins/utils";
import Agreement from "./components/Agreement";

export default {
  name: "LoginView",

  components: {
    AuthWrapper,
    HeaderBase,
    HeaderMenu,
    InputField,
    Errors,
    Agreement
  },
  mixins: [validatePassword, RegisterMixins, utils],
  data: () => ({
    lockEmailRegister: false
  })
};
</script>

<style scoped>
</style>

<template lang="pug">
	.serials-page
		header.serials-page__header
			HeaderBase
			HeaderMenu
		main.copyright__container.policy-private
			.copyright__header ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
			.copyright__text(v-html="text")
		footer
			//DefaultFooter
			//ContactInfo(show="true")


</template>

<script>
import HeaderBase from "@/components/Header";
import HeaderMenu from "@/components/HeaderMenu";
import DefaultFooter from "@/components/Footer";
import ContactUs from "@/components/ContactUs";
import Tariffs from '@/components/Tarrifs';
import Pathway from "@/components/Pathway";
import ContactInfo from "@/components/ContactInfo";
import Terms from "@/components/Terms";
import { DOCUMENT_COPYRIGHT } from '@/constants/menu-footer';

export default {
    name: "tariffs",

    components: {
        HeaderBase,
        HeaderMenu,
        DefaultFooter,
        Tariffs,
        ContactUs,
        ContactInfo,
        Pathway,
        Terms
    },
    DOCUMENT_COPYRIGHT,
    data: () => ({
        text: null,
    }),

    async created() {
        const {data: {text}} = await this.$axios.get('api/v4/terms/private-policy/');
        const domain = location.origin;
        this.text = text.replace(/{domain}/g, domain);
    }
};
</script>

<style lang="scss">
.policy-private {
  padding: 50px 70px;
  @media(max-width: 600px) {
    padding: 20px 20px;
  }
  @media(max-width: 350px) {
    padding: 20px 10px;
  }
}
.copyright {
  &__container {
    margin: 80px auto;
    @media(max-width: 600px) {
      margin: 40px 20px;
    }
  }
  &__header {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    //text-align: center;
    color: #FFFFFF;
    @media (max-width: 400px) {
      font-size: 20px;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    color: white;
    font-size: 20px;
    font-weight: 500;
    li {
      margin: 10px 0;
    }
  }
  &__item {
    color: #FFFFFF;
    margin: 10px 0;
    font-weight: 500;
    font-size: 18px;
    text-decoration: underline;
    &:hover {
      color: #3369FF;
    }
  }
  &__text {
    margin: 20px 0;
    color: white;
    font-size: 18px;
  }
}
</style>
